import React, { createContext, useContext, useState } from 'react';
import { AlbumProps } from '../../types';
import { UUID } from 'crypto';
import http from '../../services/api';

type AlbumContextType = {
    albums: AlbumProps[];
    addAlbum: (album: AlbumProps) => void;
    removeAlbum: (id: UUID) => void;
}

const AlbumContext = createContext<AlbumContextType>({ albums: [], addAlbum: () => {}, removeAlbum: () => {} });

export const useAlbumContext = () => useContext(AlbumContext);

export const AlbumProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [albums, setAlbums] = useState<AlbumProps[]>([]);

    React.useEffect(() => {
        http.get('/albums').then(response => {
            setAlbums(response.data.map((album: AlbumProps) => ({
                name: album.name,
                id: album.id,
                description: album.description
            })));
        });
    }, []);
    const addAlbum = (album: AlbumProps) => {
        setAlbums((prevAlbums) => [...prevAlbums, album]);
    };

    const removeAlbum = (id: UUID) => {
        setAlbums(albums => albums.filter(album => album.id !== id));
    };

    return <AlbumContext.Provider value={{ albums, addAlbum, removeAlbum }}>
            { children }
        </AlbumContext.Provider >;
};
