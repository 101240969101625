import React, { useState } from "react";

// import VerticalNav from "./VerticalNav";

import cx from "classnames";

import { logout } from "../../reducers/authSlice";
import { useDispatch } from "react-redux";

const Header = () => {
    const [state, setState] = useState({
        activeSearch: false,
    });

    const dispatch = useDispatch();


    return (
        <>
            <div className="d-flex justify-content-end align-items-center">
                <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 100 }}>
                    <a href="#" className="d-flex align-items-center cursor-pointer" onClick={() => dispatch(logout())}>
                        <span className="text-primary">Sign out</span>
                        <i className="fa fa-sign-out ml-2 text-primary"></i>
                    </a>
                </div>
            </div>
            <div className={cx("search-wrapper", {
                active: state.activeSearch,
            })}>
                <div className="input-holder">
                    <input type="text" className="search-input" placeholder="Type to search" />
                    <button onClick={() =>
                        setState({ activeSearch: !state.activeSearch })
                    }
                        className="search-icon">
                        <span />
                    </button>
                </div>
                <button onClick={() =>
                    setState({ activeSearch: !state.activeSearch })
                }
                    className="btn-close" />
            </div>
            <div className="back-button-wrapper" style={{ display: state.activeSearch || window.history.length === 1 ? 'none' : 'block' }}>
                <button type="button" className="btn btn-outline-primary back-button" onClick={() => window.history.back()}>
                    <i className="fa fa-arrow-left"></i>
                    Back
                </button>
            </div>
        </>
    );
}


export default Header;