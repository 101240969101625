import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, FormText, Input, Label } from 'reactstrap'
import http from '../../services/api';

import { useAlbumContext } from './AlbumContext';


interface User {
    name: string;
    /*email: string;
    password: string;
    username: string;*/
    id: string;
}

const AlbumCreate: React.FC = () => {
    const { addAlbum } = useAlbumContext(); 
    const [users, setUsers] = useState<User[]>([]);
    const [albumForm, setAlbumForm] = useState<{name: string, description: string, }>({name: "", description: ""});
    
    useEffect(() => {
        http.get(`/users/`).then(response => {
            setUsers(response.data.map((user: User) => ({
                name: user.name,
                id: user.id
            })));
        });
    }, []);

    const createAlbumHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        
        http.post(`/albums/`, formData).then(response => {
            addAlbum(response.data);
            setAlbumForm({name: "", description: ""});
        });
    };
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAlbumForm({
            ...albumForm,
            [e.target.name]: e.target.value,
        });
    };


    return (
        <>

            <Form onSubmit={createAlbumHandler}>
                <FormGroup row>
                    <Label
                        for="name"
                        sm={2}
                    >
                        Album name
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="name"
                            name="name"
                            value={albumForm.name}
                            onChange={handleChange}
                            placeholder="Album name"
                            type="text"
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label
                        for="exampleText"
                        sm={2}
                    >
                        Text Area
                    </Label>
                    <Col sm={10}>
                        <FormText>
                            Project Description
                        </FormText>
                        <Input
                            id="exampleText"
                            name="description"
                            type="textarea"
                            value={albumForm.description}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                {/* <FormGroup row>
                    <Label
                        for="exampleFile"
                        sm={2}
                    >
                        File
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                        />
                        <FormText>
                            Image when create Album
                        </FormText>
                    </Col>
                </FormGroup> */}

                <FormGroup row>
                    <Label
                        for="exampleSelectMulti"
                        sm={2}
                    >
                        Users
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="users"
                            multiple
                            name="users"
                            type="select"
                        >
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup
                    check
                    row
                >
                    <Col
                        sm={{
                            offset: 2,
                            size: 10
                        }}
                    >
                        <Button>
                            Create
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </>
    )
}

export default AlbumCreate
