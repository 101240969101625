import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../reducers/authSlice';
import { Form, Button, FormGroup, Label, Input } from 'reactstrap';

const Login: React.FC = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        dispatch(login( email, password ));
    };

    return (
        <div className="container">
            <div className="text-center mb-4">
                <img src="logo.png" alt="logo" className="mb-4" style={{ height: '100px' }} />
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1 className="text-center mb-4">Login Page</h1>
                    <Form>
                        <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input
                                type="text"
                                name="Email"
                                id="exampleEmail"
                                placeholder="Enter your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="examplePassword"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                        <Button color="primary" onClick={handleLogin}>Login</Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
