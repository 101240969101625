import React, { Component } from 'react';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faTimesCircle, faBatteryThreeQuarters } from '@fortawesome/free-solid-svg-icons';
import http from '../../services/api';

interface AlbumGalleryItemProps {
    name: string;
    path: string;
    id: string;
    version: number;
    comments: string;
    status: string;
}

class AlbumGalleryItem extends Component<AlbumGalleryItemProps> {
    deleteImage(id: string) {
        if (!window.confirm("Are you sure you want to delete this image?")) {
            return;
        }

        http.delete(`files/upload/${id}`).then(response => {
            const element = document.getElementById(id);
            const parent = element?.parentElement?.closest('.card-holder');
            if (parent) {
                parent.remove();
            }
        });

    }
    getImageUrl(path: string) {
        return `${process.env.REACT_APP_BASE_URL}${path}`;
    }
    render() {
        console.log(this.props);
        return (
            <Col md="6" sm="12" xs="12">
                <Card className="main-card mb-3"
                    id={this.props.id}
                    style={{
                        width: '18rem',
                        height: '600px',
                    }}>
                    <img
                        alt={this.props.name}
                        src={this.getImageUrl(this.props.path)}
                    />
                    <CardBody className='d-flex flex-column justify-content-between'>
                        <CardTitle>
                            <h6 className="card-title">{this.props.name}</h6>
                            {this.props.status &&
                                this.props.status === 'approved' ?
                                    <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} /> Approved</span> :
                                    this.props.status === 'needs_review' ?
                                        <span className="text-warning"><FontAwesomeIcon icon={faExclamationCircle} /> Needs Review</span> :
                                        this.props.status === 'rejected' ?
                                            <span className="text-danger"><FontAwesomeIcon icon={faTimesCircle} /> Rejected</span> :
                                            <span className="text-muted"><FontAwesomeIcon icon={faBatteryThreeQuarters} /> Wait for client to respond</span>
                            }
                        </CardTitle>
                        <CardSubtitle>
                            Version: {this.props.version}
                            <br />
                            {this.props.comments &&
                                <div style={{ height: '60px', overflowY: 'scroll' }}>
                                    {this.props.comments}
                                </div>
                            }
                        </CardSubtitle>
                        <div className="mb-auto"></div>
                        <Button color="danger" onClick={() => this.deleteImage(this.props.id)}>Delete</Button>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default AlbumGalleryItem;