import React from 'react';
import Header from './Header';

import Sidebar from './SideBar';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import Login from '../Auth/Login';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);
    
    // console.log(isAuthenticated, "isAuthenticated");
    return (
        <div className='app-container'>
            <div className="app-main">
                {isAuthenticated && <Sidebar />}
                <Col className="app-main__outer">
                    <Row>
                        {isAuthenticated && <Header />}
                    </Row>
                    <Row className="app-main__inner">
                        {isAuthenticated ? children : <Login />}
                    </Row>
                </Col>
            </div>
        </div>
    );
}

export default Layout;
