import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import http from "../services/api";

interface AuthState {
  isAuthenticated: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  error: null,
};

export const checkAuth = createAsyncThunk<boolean>(
  "auth/checkAuth",
  async () => {
    const response = await http.get("/auth/verify-auth");
    if (response.status === 200) {
      return true;
    }
    throw new Error("Not authenticated");
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state) {
      state.isAuthenticated = true;
      state.error = null;
      console.log(state)
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.isAuthenticated = action.payload;
    });
    builder.addCase(checkAuth.rejected, (state) => {
      state.isAuthenticated = false;
    });
  },
});

export const { loginSuccess, loginFailure, logoutSuccess } = authSlice.actions;

export const login =
  (email: string, password: string): any =>
  async (dispatch: any) => {
    try {
      const response = await http.post(
        "/auth/signin",
        { email, password }
      );
      if (response.status === 200) {
        dispatch(loginSuccess());
        console.info("Login successful");
      } else {
        dispatch(loginFailure("Login failed: Invalid credentials"));
      }
    } catch (error) {
      console.error("Login failed", error);
      dispatch(loginFailure("Login failed: Server error"));
    }
  };

export const logout = (): any => async (dispatch: any) => {
  try {
    const response = await http.post(
      "/auth/signout",
      {},
      { withCredentials: true }
    );
    if (response.status === 200) {
      dispatch(logoutSuccess());
      console.info("Logout successful");
    } else {
      console.error("Logout failed");
    }
  } catch (error) {
    console.error("Logout failed", error);
  }
};

export default authSlice.reducer;
