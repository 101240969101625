import React, { Component } from 'react';

class WelcomeComponent extends Component {
    render(): React.ReactNode {
        return (
            <div className="app-main__outer">
                <div className="app-main__inner p-0">
                    <div>
                        <h1>I don't know what to put here yet.</h1>
                    </div>
                </div>
            </div>
        );
    }
};

export default WelcomeComponent;