import React, { Component } from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';

import http from '../../services/api';
import AlbumItem from './AlbumItem';
import AlbumCreate from './AlbumCreate';
import { UUID } from 'crypto';

import { AlbumProps } from '../../types';   
import { useAlbumContext } from './AlbumContext';

const Album: React.FC = () => {
    const { albums } = useAlbumContext(); 
    const [showCreateForm, setShowCreateForm] = React.useState(false);


    const [prevAlbumCount, setPrevAlbumCount] = React.useState(albums.length);

    React.useEffect(() => {
        if (albums.length > prevAlbumCount) {
            setShowCreateForm(!showCreateForm);
        }
        setPrevAlbumCount(albums.length);
    }, [albums]);

    const createText = showCreateForm ? 'Hide' : 'Create';

    return (
        <div>
            {showCreateForm && (
                <div id="album-create">
                    <AlbumCreate/>
                </div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Button color="primary" onClick={() => setShowCreateForm(!showCreateForm)}>{createText}</Button>
            </div>
            <ListGroup className="rm-list-borders" flush>
                {albums.map((album: AlbumProps, index: number) => (
                    <ListGroupItem key={index} id={album.id + '-album-holder'}>
                        <AlbumItem description={album.description as string} name={album.name as string} id={album.id as string} />
                    </ListGroupItem>
                ))}
            </ListGroup>
        </div>
    );
};


/*class Album extends Component<AlbumProps, AlbumState> {
    constructor(props: AlbumProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    componentDidMount() {
        console.log('Album componentDidMount');
        http.get('/albums').then(response => {
            console.log('Album response:', response.data);
            this.setState({ data: response.data.map((album: AlbumProps) => ({
                name: album.name,
                id: album.id
            })) });
        });
    }

    render() {
        const { data } = this.state;
        console.log('Album data:', data);
        return (
            <div>
                <ListGroup className="rm-list-borders" flush>
                {data.map((album: AlbumProps, index: number) => (
                    <ListGroupItem key={index}>
                        <AlbumItem name={album.name as string} id={album.id as string} />
                    </ListGroupItem>
                ))}
                </ListGroup>
            </div>
        );
    }
};*/
export default Album;
