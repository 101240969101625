import React from 'react';
import { useParams, Params } from 'react-router-dom';

interface WithRouterParamsProps {
  params: Params;
}

const withRouterParams = <P extends WithRouterParamsProps>(WrappedComponent: React.ComponentType<P>) => {
  return (props: Omit<P, keyof WithRouterParamsProps>): JSX.Element => {
    const params = useParams();
    return <WrappedComponent {...(props as P)} params={params} />;
  };
};

export default withRouterParams;
