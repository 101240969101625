import React, { FC, useEffect, useState } from 'react';
import http from '../../services/api';
import { Link } from 'react-router-dom';

import { Button, Modal } from 'react-bootstrap';
import { UserCreate } from './UserCreate';

const User: FC = () => {
    const [users, setUsers] = useState<
        {
            id: string;
            name: string;
            email: string;
            username: string;
            password: string;
        }[]
        >([]);
    
    const [showModal, setShowModal] = useState(false); // Състояние за показване на модалния прозорец
    const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null); // Състояние за идентификатора на потребителя за изтриване

    const[showCreateForm, setShowCreateForm] = useState(false); // Състояние за показване на формата за създаване на потребител

    const handleShowModal = (userId: string) => {
        setUserIdToDelete(userId);
        setShowModal(true);
    };

    // Функция за затваряне на модалния прозорец
    const handleCloseModal = () => {
        setUserIdToDelete(null);
        setShowModal(false);
    };

    useEffect(() => {
        http.get("/users").then((response) => {
            setUsers(response.data);
        });
    }, []);

    const deleteUser = () => {
        if (userIdToDelete) {
            http.delete(`/users/${userIdToDelete}`).then(() => {
                setUsers(users.filter((user) => user.id !== userIdToDelete));
                handleCloseModal(); // Затваряме модалния прозорец след успешно изтриване
            }).catch((error) => {
                console.error('Failed to delete user:', error);
            });
        }
    };

    return (
        <div className="container mt-5">
            <div className='d-flex justify-content-center' style={{ display: "flex", justifyContent: "flex-start" }}>
                <Button color="primary" onClick={() => setShowCreateForm(!showCreateForm)}>{showCreateForm ? 'Hide' : 'Create'}</Button>
            </div>
            {showCreateForm && (
                <div id="user-create">
                    <UserCreate setShowCreateForm={setShowCreateForm} setUsers={setUsers} />
                </div>
            )}
            <h2 className="mb-4">Users List</h2>
            <ul className="list-group">
                {users.map((user) => (
                    <li className="list-group-item d-flex justify-content-between align-items-center" key={user.id}>
                        <Link to={`/users/${user.id}`}>{user.name}</Link>
                        <Button className="btn btn-danger" onClick={() => handleShowModal(user.id)}>
                            Delete
                        </Button>

                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete User</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={deleteUser}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default User;