import { useRoutes } from 'react-router-dom';
import withRouterParams from './helpers/WithRouterParams';


import WelcomeComponent from './components/Home/Welcome';
import Albums from './components/Albums/Album';
import AlbumGallery from './components/Albums/AlbumGallery';
import User from './components/Users/User';
import UserDetails from './components/Users/UserDetails';
import { UserCreate } from './components/Users/UserCreate';
import { AlbumProvider } from './components/Albums/AlbumContext';

const WrappedAlbumGallery = withRouterParams(AlbumGallery);

const AppRoutes = () => {
    const routes = useRoutes([
        { path: '/', element: <WelcomeComponent /> },
        {
            path: '/albums',
            element: <AlbumProvider><Albums /></AlbumProvider>
        },
        {
            path: '/albums/:id',
            element: <WrappedAlbumGallery />
        },
        {
            path: '/users',
            element: <User />,
            children: [
                { path: 'create', element: <UserCreate /> },
            ]
        },
        {
            path: '/users/:id',
            element: <UserDetails />
        }
        // { path: '/', element: <Outlet />, children: [{ path: '', element: <h1>Sign In</h1> }] },
    ]);

    return routes;
}

export default AppRoutes;