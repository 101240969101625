import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import http from '../../services/api';

interface UserAlbums {
    user_id: string;
    album_id: string;
}

interface UserDetailsI {
    id: string;
    name: string;
    email: string;
    username: string;
    UserAlbums: UserAlbums[];

}

const UserDetails: FC = () => {
    const { id } = useParams();
    const [selectedAlbums, setSelectedAlbums] = useState<any>([]);

    const [role, setRole] = useState<string>("");

    const [user, setUser] = useState<UserDetailsI>({} as UserDetailsI);

    const [albums, setAlbums] = useState<any[]>([]);

    useEffect(() => {
        http.get("/users/" + id, {
            params: {
                include: ["albums"]
            }
        }).then((response) => {
            setUser(response.data);
            const albums = response.data.UserAlbums.map((album: UserAlbums) => album.album_id);
            setSelectedAlbums(albums);
            setRole(response.data.role);
        });
    }, [id]);

    useEffect(() => {
        http.get("/albums/").then((response) => {
            setAlbums(response.data);
            
        });
        
    }, []);


    const handleAlbumSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        
        const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
        console.log(selectedOptions);
        setSelectedAlbums(selectedOptions);
    };

    const handleRoleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const role = event.target.value;
        setRole(role);
    };

    const handleFormSubmit = async () => {
        
        http.put("/users/" + id + '/album', {
            albumsId: selectedAlbums
        }).then((response) => {
            console.log(response);
        });

        try {
            http.patch("/users/" + id, {
                albumsId: selectedAlbums,
                role: role
            }).then((response) => {
                console.log(response);
            });

        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className="container mt-4">
            <h2 className="mb-3">User Details</h2>
            <div className="card">
                <div className="card-body">
                    <p className="card-text"><strong>Name:</strong> {user.name}</p>
                    <p className="card-text"><strong>Email:</strong> {user.email}</p>
                    <p className="card-text"><strong>Username:</strong> {user.username}</p>

                    <h3 className="mt-4">Albums:</h3>
                    <div className="mb-3">
                        <label htmlFor="albums" className="form-label">Select Albums</label>
                        <select
                            multiple
                            className="form-select"
                            name="albums[]"
                            id="albums"
                            value={selectedAlbums}
                            onChange={handleAlbumSelect}
                        >
                            {albums.map((album) => (
                                <option key={album.id} value={album.id}>{album.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="roles" className="form-label">Select Role</label>
                        <select className="form-select" name="roles" id="roles" value={role} onChange={handleRoleSelect}>
                            <option value="">Select Role</option>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                        </select>
                    </div>

                    <button className="btn btn-primary" onClick={handleFormSubmit}>Update</button>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;