import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { API_URL } from './config/config';
import AppRoutes from './app-routes';
import "./assets/base.scss";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import Layout from './components/Home/Layout';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { AppDispatch } from './store/store';
import { RootState } from './reducers/rootReducer';
import { checkAuth } from './reducers/authSlice';

function App() {
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  useEffect(() => {
    console.log("isAuthenticated:", isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(checkAuth() as any);
  }, [dispatch]);
  
  return (
    <div className="App">
      {/* <Provider store={store}> */}
        {/* <BrowserRouter> */}
          <Layout>
            <AppRoutes />
          </Layout>
          {/* </BrowserRouter> */}
        {/* </Provider> */}
    </div>
  );
}

export default App;
