import React, { Dispatch, FC, SetStateAction, useState } from "react";
import http from "../../services/api";

interface UserProps {
    setShowCreateForm?: Dispatch<SetStateAction<boolean>>;
    setUsers?: Dispatch<SetStateAction<any>>;
}

interface User {
    id: string;
    name: string;
    email: string;
    username: string;
    password: string;
}

export const UserCreate: FC<UserProps> = ({ setShowCreateForm, setUsers }) => {
    const [user, setUser] = useState({
        name: "",
        email: "",
        username: "",
        password: ""
    });
    const [repeatPassword, setRepeatPassword] = useState("");
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!user.name || !user.email || !user.username || !user.password || user.password !== repeatPassword) {
            alert("Please fill in all fields");
            return;
        }
        http.post("/users", user).then((response) => {
            if (setUsers) {
                if (setUsers) {
                    setUsers((prevState: User[]) => [...prevState, { ...response.data, id: response.data.id }]);
                }
            }
            // setUsers([...users, response.data]);
            setUser({ name: "", email: "", username: "", password: "" });
            if (setShowCreateForm) {
                setShowCreateForm(false);
            }
        });
    };

    return (
        <div className="container">
            <h2 className="mt-5">Create User</h2>
            <form onSubmit={handleSubmit} className="mt-4">
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={user.name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        className={`form-control ${user.email ? (
                            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email) ? "" : "is-invalid"
                        ) : ""}`}
                        id="email"
                        name="email"
                        value={user.email}
                        onChange={handleInputChange}
                    />
                    {user.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email) && (
                        <div className="invalid-feedback">
                            Invalid email address.
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        value={user.username}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        className={`form-control ${user.password && user.password.length < 8 ? "is-invalid" : ""}`}
                        id="password"
                        name="password"
                        value={user.password}
                        onChange={handleInputChange}
                    />
                    {user.password && user.password.length < 8 && (
                        <div className="invalid-feedback">
                            Password must be at least eight characters.
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="password">Repeat Password:</label>
                        <input
                            type="password"
                            className={`form-control ${user.password && user.password !== repeatPassword ? "is-invalid" : ""}`}
                            id="repeatPassword"
                            name="repeatPassword"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        {user.password && user.password !== repeatPassword && (
                            <div className="invalid-feedback">
                                Passwords do not match.
                            </div>
                        )}
                    </div>
                </div>
                <div className="my-4"></div>
                <div className="form-group d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary">Create User</button>
                </div>
                <div className="my-4"></div>
            </form>
        </div>
    );
};
