import React, { Component, Fragment } from "react";
// import { connect } from "react-redux";
import cx from "classnames";

import Nav from "./VerticalNav";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import PerfectScrollbar from "react-perfect-scrollbar";
// import HeaderLogo from "../AppLogo";

// import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

// import { RootState } from "../../reducers"; // Adjust the path based on your project structure



class Sidebar extends Component {
  
  render() {
    
    return (
      <Fragment>
        
            <div className="app-sidebar bg-royal sidebar-text-light sidebar-shadow">
              {/* <HeaderLogo /> */}
              <PerfectScrollbar>
                <div className="app-sidebar__inner">
                  <Nav />
                </div>
              </PerfectScrollbar>
              <div
                className="app-sidebar-bg">
            </div>
        </div>
        
      </Fragment>
    );
  }
}

export default Sidebar;
