import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import http from '../../services/api';

import AlbumGalleryItem from './AlbumGalleryItem';
import { Button, CardGroup } from 'reactstrap';
import { UUID } from 'crypto';
import { useAlbumContext } from './AlbumContext';

interface GalleryItemProps {
    name: string;
    path: string;
    id: string;
}

interface AlbumItemProps {
    name: string;
    id: string;
    description: string;
}

interface AlbumItemState {
    data: GalleryItemProps[];
}

const AlbumItem: React.FC<AlbumItemProps> = (props: AlbumItemProps) => {
    const { removeAlbum } = useAlbumContext();

    const deleteAlbum = (id: UUID) => {
        if (window.confirm('Are you sure you want to delete this album?')) {
            http.delete('/albums/' + props.id).then(response => {

                if (response.status === 204) {
                    removeAlbum(props.id as UUID);
                }
            });
        }
    }


    return (

        <div className="d-flex align-items-center album-holder">
            <Link to={`/albums/${props.id}`} state={{ name: props.name }} className="d-flex align-items-center">
                <div className="d-flex flex-column justify-content-center w-100">
                    <div className="mb-3">
                        <div className="widget-subheading">
                            {props.name}
                        </div>
                    </div>
                    {props.description &&
                        <div className="text-muted small">
                            {props.description}
                        </div>
                    }
                </div>
            </Link>
            <div className="ms-auto">
                <Button color="danger" onClick={() => { deleteAlbum(props.id as UUID); }}>
                    Delete
                </Button>
            </div>
        </div>

    );

}

export default AlbumItem;         