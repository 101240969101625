import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import http from '../../services/api';
import AlbumGalleryItem from './AlbumGalleryItem';
import { Button, CardGroup, FormGroup, FormText, Input, Form, Label, Row, Col, Container } from 'reactstrap';

interface GalleryItemProps {
    name: string;
    path: string;
    id: string;
    version: number;
    comments: string;
    status: string;
}

const AlbumGallery: React.FC = () => {
    const location = useLocation();
    const { name } = location.state || {};

    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState<GalleryItemProps[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

    const uploadFiles = async (files: FileList, albumId: string) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
        }
        formData.append('album_id', albumId);
        console.log(formData);

        const response = await http.post('/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });


        if (response.status === 200) {
            setData(prevState => [...prevState, { ...response.data.images[0], id: response.data.images[0].id }]);
            console.log(data);
        } else {
         
            console.error(response);
        }
        console.log(response);
    };

    useEffect(() => {
        http.get(`/album/${id}`).then(response => {
            setData(response.data.map((album: GalleryItemProps) => ({
                name: album.name,
                path: album.path,
                id: album.id,
                version: album.version,
                comments: album.comments,
                status: album.status
            })));
        });
    }, [id]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(event.target.files);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (selectedFiles) {
            uploadFiles(selectedFiles, id as string);
        }
    };
console.log(data);
    return (
        <>
            <Container className="justify-content-center align-items-center">
                <Row className="justify-content-center">
                    <h1 className="text-center">{name}</h1>
                    <Form onSubmit={handleSubmit} className="text-center mt-3">
                        <FormGroup>
                            <Label for="Files" className="mb-3">File</Label>
                            <Input
                                type="file"
                                name="file"
                                id="Files"
                                multiple
                                onChange={handleFileChange}
                                accept="image/*"
                                max={30}
                            />
                            <Input type='hidden' name='albumId' value={id} />
                            {/* <FormText color="muted">
                                Oho.
                            </FormText> */}
                        </FormGroup>
                        <Button color="primary" className="mt-1" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Row>
                <Row className="mt-5">
                    {
                        data.length && data.map((album: GalleryItemProps) => (
                            <Col md={4} sm={4} xs={12} key={album.id} className="card-holder">
                                <CardGroup key={album.id}>
                                    <AlbumGalleryItem key={album.id} {...album} />
                                </CardGroup>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </>
    );
};

console.log('Album Gallery');
export default AlbumGallery;
