import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
// import { RouteComponentProps, withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import MetisMenu from "react-metismenu";
// import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
// import {
//   UpgradeNav,
//   MainNav,
//   ComponentsNav,
//   FormsNav,
//   WidgetsNav,
//   ChartsNav,
// } from "./NavItems";

// Define the types for the component's props
// Define the types for the component's state
// interface NavState {}

class VerticalNav extends Component {
  

  render() {
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Big Boys Studio</h5>
        <ul className="app-menu">
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/users">Users</Link></li>
          <li><Link to="/albums">Albums</Link></li>
        </ul>
      </Fragment>
    );
  }

  isPathActive(path: string) {
    // return this.props.location.pathname.startsWith(path);
  }
}

export default VerticalNav;
